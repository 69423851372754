define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "about": {
      "colleges": {
        "excelsior": "Excelsior College",
        "rutgers": "Rutgers",
        "ualbany": "University of Albany",
        "wgu": "Western Governors University"
      },
      "intro": "DAACS is funded by the Department of Education in the Fund for the Improvement of Postsecondary Education First in the World grant program. Excelsior College is working in partnership with Western Governors University, Rutgers University, and the University at Albany. DAACS is an open source project. Institutions are free to use and adapt DAACS for the specific needs of their students.",
      "label": "About",
      "news": {
        "beyondRemediation": "Beyond Remediation: Using Technology to Maximize Retention and Completion",
        "campusTech": "Campus Technology",
        "chronicle": "The Chronicle of Higher Education",
        "deptEd": "Department of Education",
        "developingTool": "Excelsior College Developing Student Skills Assessment Tool",
        "evolllution": "The Evolllution",
        "firstInWorldGrants": "Department Awards $60 Million in First in the World Grants to 17 Colleges, Universities and Organizations",
        "grantAwarded": "Excelsior College awarded $2.9 million grant to assess student skills, predict future success",
        "label": "In the News!",
        "measuringSkills": "Measuring Academic Skills and ‘Grit’ to Help Identify At-Risk Students"
      },
      "personnel": {
        "advisory": {
          "dWalsh": "Darren Walsh, Excelsior College",
          "gBraddock": "Glenn Braddock, Ph.D., Excelsior College",
          "jLevin": "Jason Levin, MBA, Western Governors University",
          "lDaniels": "Lisa Daniels, Ph.D., Excelsior College",
          "label": "Advisory Committee",
          "pJones": "Patrick Jones, Ph.D., Excelsior College",
          "pWinne": "Phil Winne, Ph.D., Simon Fraser University",
          "rDugan": "Ronald Dugan, Ph.D., The College of Saint Rose"
        },
        "coProjDirector": {
          "hAndrade": "Heidi Andrade, Ed.D., University at Albany",
          "label": "Co-Project Directors",
          "tCleary": "Timothy Cleary, Ph.D., Rutgers University"
        },
        "evaluator": {
          "bStorandt": "Barbara Storandt, ALTA Solutions Group, LLC",
          "label": "Evaluator"
        },
        "other": {
          "aHerzig": "Abbe Herzig, Ph.D., Subject Matter Expert",
          "aLui": "Angela Lui, M.S.",
          "bBarros": "Bethany de Barros, Grants",
          "cDow": "Clayton Dow, Fiscal",
          "cValle": "Chris Valle, Ph.D., Analytics",
          "dAiken": "Donn Aiken, Information Technology",
          "dAkhmedjanova": "Diana Akhmedjanova, M.S.",
          "dFranklin": "David Franklin, M.S.",
          "fCrocco": "Francesco Crocco, Ph.D., Subject Matter Expert",
          "jWeyers": "Jane Weyers, Grants",
          "kHogan": "Kara Hogan, Ph.D.",
          "label": "Other Personnel",
          "mVerdi": "Marc Verdi, Project Manager",
          "pCroop": "Patti Croop, Grants"
        },
        "projDirector": {
          "jBryer": "Jason Bryer, Ph.D., Excelsior College",
          "label": "Project Director"
        }
      }
    },
    "actions": {
      "create": "Create",
      "edit": "Edit",
      "import": "Import",
      "print": "Print",
      "save": "Save"
    },
    "admin": {
      "activate": "Activate",
      "activateError": "Unable to update the assessment. Please correct any errors and try again.",
      "activated": "The assessment has been activated",
      "active": "Active",
      "activeAssessments": "Active Assessments",
      "allAssessments": "All Assessments",
      "assessment": "Assessment",
      "assessmentDetails": {
        "back": "Back to list",
        "configUpdated": "The assessment configuration has been updated",
        "configuration": "Grading Configuration",
        "copy": "Copy",
        "copyError": "Could not copy assessment data. Your browser may not support this operation.",
        "copySuccess": "Assessment data copied to clipboard.",
        "delete": "Delete",
        "edit": "Edit",
        "label": "Assessment Details",
        "notConfigurable": "This assessment type is set up for automatic grading and is not configurable.",
        "save": "Save"
      },
      "assessmentLabel": "Label",
      "bert": {
        "fileInvalid": "The selected file must have a file type of .pkl",
        "modelFile": "BERT model file",
        "modelFiles": "BERT model files",
        "overallModel": "Overall model",
        "uploadSuccess": "The BERT model file was uploaded successfully."
      },
      "categories": {
        "all": "Show all assessments",
        "filterBy": "Filter by category",
        "label": "Assessment Categories",
        "mathematics": "Mathematics",
        "reading": "Reading",
        "srl": "Self-Regulated Learning",
        "writing": "Writing"
      },
      "category": {
        "add": "Add category",
        "cancel": "Cancel",
        "createSuccess": "The assessment category has been created successfully.",
        "edit": "Edit category",
        "groupLabel": "Label",
        "id": "ID",
        "label": "Assessment Categories",
        "labelInstructions": "This label is for internal purposes only, and will not be visible to students.",
        "none": "No categories found",
        "remove": "Remove",
        "removeConfirm": "Are you sure you want to remove this category?<br><br>Categories cannot be deleted if they have associated assessments.",
        "removeError": "An error occurred while attempting to remove the category. Please make sure no assessments are associated with this category.",
        "removeSuccess": "The assessment category has been removed successfully.",
        "samlField": "SAML Field",
        "samlInstructions": "SAML configuration is optional. If you provide a SAML field, you must also provide a value.",
        "samlValue": "SAML Value",
        "save": "Save",
        "type": "Category Type",
        "updateSuccess": "The assessment category has been updated successfully."
      },
      "completionDate": "Completion Date",
      "createAssessment": {
        "create": "Create",
        "label": "Create Assessment"
      },
      "createdDate": "Created Date",
      "createdOn": "Created on {date}",
      "deactivate": "Deactivate",
      "deactivated": "The assessment has been deactivated",
      "details": "Details",
      "editAssessment": {
        "assessmentContent": "Assessment Content",
        "content": {
          "help": {
            "description": "HTML content displayed in the overlay dialog that can be accessed by the student while taking the assessment via the \"Help\" menu.",
            "label": "Help Dialog"
          },
          "helpLabel": {
            "description": "Text that is displayed for the option in the \"Help\" menu that will open the help content dialog.",
            "label": "Help Menu Text"
          },
          "label": "Content",
          "landing": {
            "description": "HTML content displayed in the myDAACS dashboard tab for the currently highlighted assessment.",
            "label": "Dashboard Tab"
          },
          "start": {
            "description": "HTML content displayed in the left-hand column of the \"splash\" screen that is displayed when the student starts a new assessment.",
            "label": "Start Page"
          },
          "startTips": {
            "description": "HTML content displayed in the right-hand sidebar column of the \"splash\" screen that is displayed when the student starts a new assessment.",
            "label": "Start Page Tips"
          }
        },
        "domains": {
          "add": "Add domain",
          "cannotBeSubdomain": "This domain has subdomains, and cannot have a parent domain.",
          "domain": {
            "description": "HTML content that is displayed at the top of the domain results page, no matter what score was given.",
            "info": "General Information",
            "label": "Domain"
          },
          "edit": "Edit domain",
          "id": "ID",
          "label": "Domains",
          "name": "Name",
          "noName": "No name provided",
          "noParent": "No parent domain",
          "none": "No domains found",
          "parent": "Parent Domain",
          "remove": {
            "cancel": "Cancel",
            "confirm": "Are you sure you want to remove this domain?",
            "confirmMultiple": "Are you sure you want to remove this domain? Its subdomains will also be removed.",
            "label": "Remove"
          },
          "scoreIsAvg": "Score is an average of its sub domains",
          "type": "Type"
        },
        "general": {
          "category": "Category",
          "label": "General",
          "maxTakenGroups": "Max Taken Groups",
          "minTakenGroups": "Min Taken Groups",
          "name": "Name",
          "prereq": {
            "add": "Add prerequisite",
            "category": "Category",
            "label": "Prerequisites",
            "none": "No prerequisites",
            "reason": "Reason",
            "remove": "Remove prerequisite",
            "statuses": "Statuses"
          },
          "scoring": "Scoring",
          "selectCategory": "Select a category",
          "selectDifficulty": "Select a difficulty",
          "selectScoringType": "Select a scoring type",
          "selectType": "Select a type",
          "selectTypeFirst": "Please select an assessment type first",
          "startingDifficulty": "Starting Difficulty",
          "type": "Type"
        },
        "label": "Edit Assessment",
        "questions": {
          "FORMULA": "Formula",
          "PASSAGE": "Passage",
          "WORD": "Word Problem",
          "add": "Add question",
          "answers": {
            "add": "Add",
            "addAnswer": "Add answer",
            "answers": "Answers",
            "edit": "Edit answers",
            "label": "Answer",
            "none": "No answers found",
            "points": "Points",
            "remove": "Remove",
            "toggleEditor": "Toggle content editor"
          },
          "cat": {
            "group": {
              "add": "Add group",
              "label": "Group {num}",
              "none": "No groups found",
              "remove": {
                "cancel": "Cancel",
                "confirm": "Are you sure you want to remove this group?",
                "label": "Remove"
              }
            },
            "transitions": {
              "exclusive": "Exclusive",
              "inclusive": "Inclusive",
              "infinity": "Infinity",
              "label": "Transitions",
              "negInfinity": "-Infinity",
              "transitionTo": "to"
            }
          },
          "content": "Question content",
          "domain": "Domain",
          "edit": "Edit question",
          "feedback": {
            "label": "Feedback",
            "supplement": "Feedback supplement"
          },
          "import": {
            "action": "Import questions...",
            "catAssessmentInstructions": "Select an existing Multiple Choice or CAT assessment to import questions from.",
            "label": "Import questions",
            "likertAssessmentInstructions": "Select an existing Likert assessment to import questions from.",
            "questionsInstructions": "Select the groups of questions your wish to import from the \"{label} <span class=\"text-muted\">({date})</span>\" assessment.",
            "selectAssessment": "Select assessment",
            "selectQuestions": "Select questions"
          },
          "label": "Questions",
          "likert": {
            "group": {
              "add": "Add group",
              "edit": "Edit group",
              "label": "Group {num}",
              "none": "No groups found",
              "options": "This Likert group uses the following answers (5 max):",
              "pointsDist": "Answer points distribution",
              "pointsFor": "points for <strong>{option}</strong>",
              "remove": {
                "cancel": "Cancel",
                "confirm": "Are you sure you want to remove this group?",
                "label": "Remove"
              }
            }
          },
          "noText": "No question text",
          "none": "No questions found",
          "questionNum": "Question {num}",
          "remove": {
            "cancel": "Cancel",
            "confirm": "Are you sure you want to remove this question?",
            "label": "Remove"
          },
          "selectAssessmentType": "You must select an assessment type first",
          "selectDomain": "Select a domain",
          "subdomains": "Subdomains",
          "supplement": {
            "hasContent": "Show supplement content",
            "imageExample": "https://example.com/image.jpg",
            "label": "Question supplement",
            "none": "No supplement"
          },
          "text": "Question text",
          "writingPrompt": {
            "description": "HTML content that is displayed above the text input for Writing assessments.",
            "label": "Writing prompt",
            "minWords": "Minimum number of words"
          }
        },
        "rubric": {
          "content": "Content",
          "description": "HTML content that is displayed in the assessment results index page, based on the stuent's overall score.",
          "exclusive": "Exclusive",
          "inclusive": "Inclusive",
          "label": "Rubric",
          "scoreTo": "to",
          "summary": "Summary"
        },
        "save": "Save",
        "scores": {
          "HIGH": "High Score",
          "LOW": "Low Score",
          "MEDIUM": "Medium Score",
          "summary": {
            "HIGH": "High Score Summary",
            "LOW": "Low Score Summary",
            "MEDIUM": "Medium Score Summary"
          }
        },
        "submitError": "Please correct the errors in the form and resubmit.",
        "submitSuccess": "Changes have been saved."
      },
      "export": "Export",
      "exportAll": "Export All",
      "exportAllManualGrade": "Exports all ungraded assessments that are manually graded.",
      "graded": "Graded",
      "grading": "Grading...",
      "gradingFailed": "Automatic grading of the assessment failed.",
      "gradingFailedUnexpected": "An unexpected error occurred while attempting to grade the assessment.",
      "gradingQueued": "The request to grade the assessment has been queued. Please refresh this list in a few minutes to check its status.",
      "import": {
        "assessment": "Import assessment",
        "confirmSave": "Are you sure you want to import this as an active assessment? Remember to disable any currently active assessments as needed.",
        "definition": "Assessment definition",
        "file": "Assessment File",
        "instructions": "Upload your assessment definition or copy/paste its contents into the field below",
        "json": "Assessment JSON",
        "label": "Import",
        "pasteJson": "Enter the assessment JSON data below",
        "readFileError": "Unable to load the assessment JSON data from the selected file",
        "save": "Save"
      },
      "inactive": "Inactive",
      "label": "Admin",
      "lightside": {
        "fileInvalid": "The selected file must have a file type of .xml",
        "modelFile": "LightSide model file",
        "modelFiles": "LightSide model files",
        "overallModel": "Overall model",
        "uploadSuccess": "The LightSide model file was uploaded successfully."
      },
      "manageAssessments": "Manage Assessments",
      "manageAssessmentsDescription": "Listed below are all assessments, both active and inactive. Use the filter menu to only show assessments of a particular category, or use the list on the left to access the active assessments.",
      "manageUsers": "Manage Users",
      "manualGrade": {
        "assessment": "Grade Assessment",
        "back": "Back to list",
        "domainScores": "Domain Scores",
        "high": "High",
        "low": "Low",
        "medium": "Medium",
        "selectPlaceholder": "Select a score",
        "submit": "Submit",
        "successMsg": "The assessment has been graded successfully",
        "unscoredDomains": "Please select a score for all domains to continue"
      },
      "noActiveAssessmentsFound": "No active assessments found",
      "noAssessmentsFound": "No assessments found",
      "numCompletions": {
        "one": "1 completion",
        "other": "{count} completions"
      },
      "numUngraded": {
        "one": "1 ungraded",
        "other": "{count} ungraded"
      },
      "reload": "Reload",
      "results": "Results",
      "retryGrading": "Retry Grading",
      "scoring": {
        "anyMethod": "Any scoring method",
        "automatic": "Automatic",
        "automaticScoring": "Automatic Scoring",
        "filterBy": "Filter by scoring method",
        "manual": "Manual",
        "manualScoring": "Manual Scoring",
        "method": "Scoring Method",
        "methodUpdateError": "An error occurred while attempting to update the scoring method",
        "methodUpdated": "The scoring method has been updated"
      },
      "status": "Status",
      "student": "Student",
      "ungradedAssessments": "Ungraded Assessments",
      "ungradedAssessmentsDescription": "Listed below are all ungraded assessments. From this list, you may retry grading (for assessments with automatic scoring enabled), or view the assessment to grade manually.",
      "view": "View"
    },
    "alert": {
      "close": "Close"
    },
    "assessment": {
      "answerAllQuestions": "Please answer all questions before proceeding",
      "autoGradedResultsAvailable": "Your results and feedback will be available here. Please refresh your browser in one minute.",
      "backToDashboard": "Back to myDAACS",
      "backToOverview": "Assessment overview",
      "cheatSheet": "Cheat Sheet",
      "checkForResults": "Check For Results",
      "completed": "Completed",
      "confirmLeave": "Are you sure you want to leave? Your work on the current page has not been saved.",
      "continue": "Continue",
      "continueAssessment": "Continue Assessment",
      "continueCurrent": "Continue Current Assessment",
      "continueLater": "Continue Later",
      "details": "Assessment details",
      "doesNotExist": "Assessment does not exist",
      "domain": {
        "answerIs": "The answer is",
        "correct": "Correct",
        "correctAnswerIs": "The correct answer is",
        "domainOverview": "Domain Overview",
        "feedback": "Feedback",
        "incorrect": "Incorrect",
        "itemIsCorrect": "You got this item correct.",
        "lessInfo": "Less Info",
        "moreInfo": "More Info",
        "overview": "Overview",
        "questionDetails": "Question Details",
        "questions": "Questions",
        "viewImage": "View image",
        "yourAnswer": "Your answer",
        "yourAnswerWas": "Your answer was",
        "yourPerformance": "Your Performance",
        "yourResponse": "Your Response"
      },
      "finishedMessage": "Congratulations, you have completed the {label} assessment!",
      "help": {
        "body": "Body",
        "label": "Help",
        "request": "Request Help",
        "requestReceived": "Your help request has been received. An administrator will contact you shortly to assist with this request.",
        "submit": "Submit",
        "thanks": "Thanks!"
      },
      "inProgress": "In Progress",
      "isInProgress": "This assessment is still in progress. Please complete it to see your results.",
      "label": "Assessment",
      "learnMore": "Learn More",
      "manualGradedResultsAvailable": "Your results and feedback will be available here. Please check back later.",
      "neverTaken": "You haven't taken this assessment yet.",
      "nextQuestion": "Next",
      "noCompletionsFound": "No completions found",
      "notStarted": "Not Started",
      "notYetGraded": "This assessment has not yet been graded. Please check back later.",
      "notYetGradedWriting": "Thank you for completing your DAACS writing assessment. We have provided a copy of your response below.",
      "performance": {
        "high": "High Performance",
        "low": "Low Performance",
        "medium": "Medium Performance",
        "notGraded": "Not Graded"
      },
      "prerequisites": "Assessment Prerequisites",
      "retake": "Retake Assessment",
      "selectCompletionDate": "Select a completion date",
      "showResultsFrom": "Show Results From",
      "smallDevice": {
        "message": "In order to ensure that the questions and related content are displayed properly, you must use a larger device, such as a tablet or desktop computer, to take this assessment. (992px minimum horizontal resolution)",
        "title": "Your device is too small to view this page",
        "useLandscape": "If you are on a tablet, try rotating your device to view this page in landscape mode."
      },
      "start": "Start",
      "startAssessment": "Start Assessment",
      "submitError": "Sorry, an error occurred while attempting to submit your response. Please try again or use the Help menu to notify an administrator.",
      "tips": "Helpful Tips",
      "unsavedWork": "You have unsaved work",
      "viewItemContent": {
        "FORMULA": "View formula",
        "PASSAGE": "View reading passage",
        "WORD": "View word problem"
      },
      "viewPreviousResults": "View previous results",
      "writingPrompt": {
        "changesSaved": "Your changes have been saved.",
        "meetMinWords": "You must meet the minimum words requirement before proceeding",
        "minWords": {
          "one": "Minimum 1 word",
          "other": "Minimum {count} words"
        },
        "placeholder": "Type your response here",
        "reqWords": {
          "one": "1 more word required",
          "other": "{count} more words required"
        },
        "savingChanges": "Saving changes..."
      },
      "youAreDone": "You're done!"
    },
    "brand": {
      "copyright": "© {year}. All rights reserved.",
      "name": "DAACS"
    },
    "classes": {
      "accepted": "Accepted",
      "addStudents": "Add Students",
      "addedStudentsSuccess": "Added student(s) successfully.",
      "back": "Back to class list",
      "classInvite": {
        "acceptClassInvite": "Accept Class Invite",
        "acceptedSuccessfully": "Accepted class invite successfully.",
        "title": "Class Invite"
      },
      "createClass": {
        "assessments": "Assessments",
        "className": "Class Name",
        "general": "General",
        "label": "Create Class",
        "selectAssessments": "Select Assessments",
        "students": "Students",
        "submitError": "Please correct the errors in the form and resubmit.",
        "submitSuccess": "Class created successfully."
      },
      "doNotSendConfirmationEmail": "Do not send confirmation email",
      "downloadResults": "Download Results",
      "downloadSuccess": "Download started...",
      "editClass": {
        "label": "Edit Class",
        "submitSuccess": "Changes saved successfully."
      },
      "import": {
        "file": "CSV File",
        "importFailed": "Unable to import classes.",
        "importSuccess": "Imported classes successfully.",
        "title": "Import Classes"
      },
      "label": "Classes",
      "noClassesFound": "No classes found",
      "noStudentsFound": "No students found",
      "pendingInvite": "Pending Invite",
      "resendInvite": "Resend Invite",
      "score": "{domain}: {score}",
      "scores": "Scores",
      "search": {
        "label": "Search instructors...",
        "noneFound": "No instructors found",
        "select": "Select an instructor"
      },
      "sendInvites": "Send Invites",
      "sentInvitesSuccess": "Sent class invite(s) successfully.",
      "status": "Status",
      "student": "Student",
      "studentEmail": "Student Email",
      "students": "Students",
      "unableToAddStudents": "Unable to add student(s). Check that the email(s) are correct and try again.",
      "unableToSendInvite": "Unable to send class invite(s). Check that a class is selected and the email(s) are correct and try again."
    },
    "confirmation": {
      "continue": "Are you sure you want to continue?",
      "no": "No",
      "yes": "Yes"
    },
    "cookies": {
      "close": "Close",
      "message": "This site requires cookies to be enabled. Please correct your browser settings to enable cookies and refresh this page to continue.",
      "title": "Please Enable Cookies"
    },
    "createAccount": {
      "advisor": "Advisor",
      "email": "Email Address",
      "firstName": "First Name",
      "instructor": "Instructor",
      "label": "Create Account",
      "lastName": "Last Name",
      "password": "Password",
      "passwordConfirm": "Confirm Password",
      "role": "Role",
      "selectRole": "Select a role",
      "student": "Student",
      "success": "Account created successfully. Please check your email to verify your account."
    },
    "dashboard": {
      "label": "myDAACS"
    },
    "dialog": {
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "done": "Done",
      "finish": "Finish",
      "next": "Next",
      "ok": "OK"
    },
    "errors": {
      "accepted": " must be accepted",
      "blank": " can't be blank",
      "confirmation": "doesnt match {attribute}",
      "empty": " can't be empty",
      "equalTo": " must be equal to {count}",
      "even": " must be even",
      "exclusion": " is reserved",
      "greaterThan": " must be greater than {count}",
      "greaterThanOrEqualTo": " must be greater than or equal to {count}",
      "inclusion": " is not included in the list",
      "invalid": " is invalid",
      "lessThan": " must be less than {count}",
      "lessThanOrEqualTo": " must be less than or equal to {count}",
      "notANumber": " is not a number",
      "notAnInteger": " must be an integer",
      "odd": " must be odd",
      "otherThan": " must be other than {count}",
      "present": " must be blank",
      "tooLong": " is too long (maximum is {count} characters)",
      "tooShort": " is too short (minimum is {count} characters)",
      "validJson": " must be a valid JSON document",
      "wrongLength": " is the wrong length (should be {count} characters)"
    },
    "fileUpload": {
      "noneChosen": "No file chosen",
      "select": "Select file...",
      "uploading": "Uploading, please wait..."
    },
    "footer": {
      "disclaimer": "DAACS was developed under grants #P116F150077 and #R305A210269 from the U.S. Department of Education. However, the contents do not necessarily represent the policy of the U.S. Department of Education, and you should not assume endorsement by the Federal Government.",
      "slogan": "DAACS is a diagnostic assessment designed to help students transition to college. DAACS provides personalized feedback and links to resources to help them be successful students."
    },
    "fourOhFour": {
      "message": "Sorry, the page you're looking for could not be found.",
      "pageNotFound": "404 Page not found"
    },
    "fourOhThree": {
      "forbidden": "403 Forbidden",
      "message": "Sorry, but you do not have the proper credentials to view this page."
    },
    "home": {
      "analysis": {
        "content": "Data provided by DAACS has been shown to increase the accuracy of predictive analytic models. These data not only help institutions identify their most academically at-risk students, but also provide specific information as to how they are at risk. With this information, more targeted interventions can be developed that better serve students’ needs.",
        "label": "PREDICTIVE ANALYTICS"
      },
      "assess": {
        "content": "Current research suggests that self-regulated learning, mindset, and motivation are better predictors of college success than traditional placement or entrance exams. DAACS places emphasis on these areas and provides students with concrete strategies for becoming more successful college students.",
        "label": "FOCUSING ON THE WHOLE STUDENT"
      },
      "label": "Home",
      "learn": {
        "content": "After completing DAACS, students receive immediate feedback about their strengths while identifying areas in need of improvement",
        "label": "Learn"
      },
      "measure": {
        "content": "DAACS assesses critical college skills.",
        "label": "Measure",
        "mathematics": "Mathematics",
        "reading": "Reading",
        "srl": "Self-Regulated Learning",
        "writing": "Writing"
      },
      "progress": {
        "complete": "Students complete DAACS online, at their own pace.",
        "feedback": "Once done, students receive immediate, personalized feedback.",
        "label": "DAACS Progress",
        "resources": "DAACS results help students pick the right courses and provides resources that can bolster college skills.",
        "success": "Students who have the skills to be successful in their first courses are more likely to complete college."
      },
      "rethink": {
        "content": "In the past, newly enrolled college students had little reliable information about their readiness for college-level academic work. DAACS addresses this problem by providing students with detailed information and feedback on their preparation for college, as well as resources for shoring it up. By using DAACS, students can build needed skills in areas of self-regulated learning, writing, reading, and mathematics while enrolled in courses that lead to the completion of their degrees.",
        "label": "RETHINKING COLLEGE READINESS"
      },
      "signIn": "Sign in to get started",
      "slogan": "DAACS is a diagnostic assessment designed to help students transition to college. DAACS provides personalized feedback about students’ strengths and weaknesses in terms of key academic and self-regulated learning skills, linking them to the resources to help them be successful students.",
      "support": {
        "coaches": "Coaches, Advisors & Faculty",
        "content": "Students are directed to resources designed to promote skill development",
        "label": "Support",
        "resources": "Open Education Resources",
        "writingLab": "Online Writing Lab"
      }
    },
    "login": {
      "authFailed": "Authentication failed, please try again.",
      "email": "Email Address",
      "forgotPassword": "Forgot your password?",
      "label": "Sign in",
      "password": "Password",
      "samlAuthFailed": "Sorry, your authentication request has failed."
    },
    "logout": {
      "label": "Log out"
    },
    "password": {
      "backToLogin": "Back to login",
      "confirm": "Confirm Password",
      "email": "Email Address",
      "instructions": "We'll email you instructions on how to reset your password.",
      "label": "Password",
      "reset": "Reset",
      "resetHeading": "Reset your password",
      "resetPasswordThankyou": "Your password was reset, and you can login with your new password.",
      "resetPasswordTokenInstruction": "Please enter your email, a new password, and confirmation of that new password.",
      "resetSuccess": "Your password has been reset. Please login.",
      "send": "Send",
      "sendSuccess": "An email has been sent to the email address associated with this username with instructions to reset your password."
    },
    "routeError": {
      "message": "Sorry, an error occurred while attempting to load this page.",
      "title": "System Error"
    },
    "select": {
      "loading": "Loading..."
    },
    "serverError": {
      "codes": {
        "Assessment": {
          "constraintViolation": {
            "assessmentCategory": {
              "NotNull": "The assessment category can't be blank.",
              "ValidBaseAssessment": "The selected category is not compatible with the assessment type."
            },
            "assessmentType": {
              "NotNull": "The assessment type can't be blank.",
              "ValidBaseAssessment": "The selected assessment type is not valid."
            },
            "bertConfig": {
              "ValidWritingAssessment": "A valid BERT configuration file must be provided for each domain.",
              "domainModels": {
                "ValidWritingAssessment": "A valid BERT configuration file must be provided for each domain."
              }
            },
            "content": {
              "NotNull": "The assessment content can't be blank."
            },
            "domains": {
              "ValidBaseAssessment": "Domain IDs must be unique.",
              "ValidWritingAssessment": "Domain must contain a rubric if not scored by an average of its sub-domains",
              "content": {
                "NotNull": "The domain General Information content can't be blank."
              },
              "domainType": {
                "NotNull": "The domain type can't be blank."
              },
              "id": {
                "NotNull": "The domain ID can't be blank."
              },
              "label": {
                "NotNull": "The domain label can't be blank."
              },
              "rubric": {
                "ValidBaseAssessment": "The rubric score configuration for the domain is not valid."
              }
            },
            "itemGroupTransitions": {
              "ValidCATAssessment": "All question groups must have the same number of questions.",
              "transitionMap": {
                "ValidCATAssessment": "The question group transition map has a gap in its coverage."
              }
            },
            "itemGroups": {
              "Size": "The assessment must have at least 1 question group",
              "ValidCATAssessment": "The question group configuration is not valid. Please make sure there is a correct number of groups per difficulty.",
              "ValidWritingAssessment": "An question group's lowest possible score is same as highest possible score.",
              "domainID": {
                "ValidCATAssessment": "The provided domain for a question group is not valid."
              },
              "items": {
                "domainId": {
                  "NotNull": "The question domain can't be blank."
                },
                "itemContent": {
                  "NotNull": "The question content can't be blank."
                },
                "possibleItemAnswers": {
                  "content": {
                    "NotNull": "The question answer content can't be blank."
                  },
                  "score": {
                    "NotNull": "The question answer score can't be blank."
                  }
                },
                "question": {
                  "NotNull": "The question text can't be blank."
                }
              },
              "possibleItemAnswers": {
                "content": {
                  "NotNull": "The question group answer content can't be blank."
                }
              }
            },
            "label": {
              "NotNull": "The assessment label can't be blank."
            },
            "lightSideConfig": {
              "ValidWritingAssessment": "A valid lightside configuration file must be provided for each domain.",
              "domainModels": {
                "ValidWritingAssessment": "A valid lightside configuration file must be provided for each domain."
              }
            },
            "maxTakenGroups": {
              "ValidCATAssessment": "Max taken groups must be equal to or larger than the min taken groups."
            },
            "minTakenGroups": {
              "ValidCATAssessment": "Min taken groups must be larger than 0."
            },
            "numQuestionsPerGroup": {
              "Min": "All groups must have the same number of questions, and there must be at least one question per group."
            },
            "overallRubric": {
              "ValidBaseAssessment": "The rubric score configuration is not valid.",
              "ValidWritingAssessment": "The rubric score configuration is not valid.",
              "completionScoreMap": {
                "NotNull": "The rubric score configuration can't be blank.",
                "ValidWritingAssessment": "Writing assessments cannot have a rubric score configuration."
              },
              "supplementTable": {
                "ValidWritingAssessment": "The rubric does not contain score supplement information.",
                "completionScore": {
                  "NotNull": "The rubric score configuration can't be blank."
                },
                "content": {
                  "NotNull": "The rubric score content can't be blank."
                }
              }
            },
            "prerequisites": {
              "assessmentCategory": {
                "NotNull": "A category must be selected for the assessment prerequisite."
              },
              "prereqType": {
                "NotNull": "The assessment prerequisite type can't be blank."
              },
              "reason": {
                "NotNull": "The assessment prerequisite reason can't be blank."
              }
            },
            "scoringType": {
              "NotNull": "The scoring type can't be blank.",
              "ValidBaseAssessment": "The selected scoring type is not compatible with the assessment type.",
              "ValidWritingAssessment": "The selected scoring type is not compatible with the assessment type."
            },
            "startingDifficulty": {
              "NotNull": "The starting difficulty can't be blank."
            },
            "writingPrompt": {
              "content": {
                "NotNull": "The writing prompt content can't be blank."
              },
              "minWords": {
                "NotNull": "The writing prompt minimum words can't be blank."
              }
            }
          }
        },
        "MongoDB": {
          "requestFailed": "We're having trouble completing your request...please try again in a moment."
        },
        "createUserRequest": {
          "constraintViolation": "Role must be either student or instructor"
        },
        "javaMailSender": {
          "requestFailed": "We're having trouble completing your request...please try again in a moment."
        },
        "sendClassInviteRequest": {
          "constraintViolation": {
            "classId": {
              "NotNull": "Cannot send invite(s) without a selected class. Please select a class and try again. If there are no classes, please create one and try again."
            }
          }
        },
        "unknown": {
          "unexpected": "An unexpected error has occurred. If the problem persists, please contact the system administrator."
        }
      },
      "msg": "Sorry, the server encountered an error while attempting to process your request.",
      "title": "Server Error"
    },
    "sharedTranslation": {
      "schoolEntity": "If you are at one of these schools, an account has been created for you. Click your school logo to log in to your account."
    },
    "skipNavigation": {
      "label": "Skip to main content"
    },
    "student": {
      "noneFound": "No students found",
      "search": "Search students...",
      "select": "Select a student"
    },
    "tables": {
      "noResults": "No results found"
    },
    "truncateText": {
      "seeLess": "See Less",
      "seeMore": "See More"
    },
    "unknown": {
      "unexpected": "Sorry, an unexpected error has occurred."
    },
    "userConsent": {
      "consent": "I CONSENT",
      "consentDescription": "I give the DAACS team permission to use my information, and understand my responses will remain confidential, reported only in aggregate.",
      "dontConsent": "I DO NOT CONSENT",
      "dontConsentDescription": "I do not give the DAACS team permission to use my information.",
      "label": "Data Usage Consent"
    },
    "username": {
      "forgotUsername": "Forgot your username?",
      "forgotUsernameInstruction": "Enter your email, and if we found it you will recieve an email with your username.",
      "resetHeading": "Get your username",
      "sendSuccess": "An email has been sent to the email address associated with this username."
    }
  }]];
});